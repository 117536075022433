import { defineMessages } from 'react-intl';

const generalMessages = defineMessages({
  UV420: {
    defaultMessage: 'Blue Light filter',
    id: 'profile.orderDetails.blueLightFilter'
  },
  ATGREY01TR: {
    defaultMessage: 'Light Responsive Lenses',
    id: 'profile.orderDetails.photochromic'
  },
  addNewPrescriptionCta: {
    defaultMessage: 'Add new prescription',
    id: 'userProfile.prescriptions.addNewPrescriptionCta'
  },
  backToMyPrescriptions: {
    defaultMessage: 'Back to my prescriptions',
    id: 'profile.prescriptions.backToMyPrescriptions'
  },
  bookAFreeEyeTestCta: {
    defaultMessage: 'Book a free eye test',
    id: 'userProfile.prescriptions.bookAFreeEyeTestCta'
  },
  bookAnEyeTestCta: {
    defaultMessage: 'Book an eye test',
    id: 'userProfile.prescriptions.bookAnEyeTestCta'
  },
  bookAnEyeTestTitle: {
    defaultMessage: 'Hey! Time to update your prescription?',
    id: 'userProfile.prescriptions.bookAnEyeTestTitle'
  },
  bookAnEyeTestWithUs: {
    defaultMessage: 'We offer free eye tests at all of our stores. Go ahead and book an appointment with us online.',
    id: 'userProfile.prescriptions.bookAnEyeTestWithUs'
  },
  cancelOrder: {
    defaultMessage: 'Cancel order',
    id: 'profile.orderDetails.cancelOrder'
  },
  cancelOrderDescription: {
    defaultMessage: 'Are you sure you want to cancel your order? You will not be able to undo this action.',
    id: 'profile.orderDetails.cancelOrderDescription'
  },
  cancelOrderTitle: {
    defaultMessage: 'Cancel order?',
    id: 'profile.orderDetails.cancelOrderTitle'
  },
  cancelled: {
    defaultMessage: 'your order has been cancelled',
    id: 'dashboard.orderTable.cancelled'
  },
  changePassword: {
    defaultMessage: 'Change Password',
    id: 'userProfile.profile.changePasswordTitle'
  },
  changePasswordDisclaimer: {
    defaultMessage: 'When changing your password you will be logged out. Please login with your new credentials.',
    id: 'userProfile.profile.changePasswordDisclaimer'
  },
  confirmCancelOrder: {
    defaultMessage: 'Yes, cancel',
    id: 'profile.orderDetails.confirmCancelOrder'
  },
  confirmReturnText: {
    defaultMessage:
      'Please check your personal details and complete the form to be able to generate your return label.',
    id: 'returnFlow.confirmReturnText'
  },
  contactsTab: {
    defaultMessage: 'Contacts',
    id: 'userProfile.prescriptions.contactsTab'
  },
  customerSupportLink: {
    defaultMessage: 'customer support',
    id: 'profile.orderDetails.customerSupportLink'
  },
  'dashboard.orderDetails.address.title': {
    defaultMessage: 'Address',
    id: 'dashboard.orderDetails.address.title'
  },
  'dashboard.orderDetails.contactDetails.title': {
    defaultMessage: 'Contact details',
    id: 'dashboard.orderDetails.contactDetails.title'
  },
  'dashboard.orderDetails.deliveryDetails.title': {
    defaultMessage: 'Delivery details',
    id: 'dashboard.orderDetails.deliveryDetails.title'
  },
  'dashboard.orderDetails.paymentOverview.title': {
    defaultMessage: 'Payment Method',
    id: 'dashboard.orderDetails.paymentOverview.title'
  },
  'dashboard.orderDetails.prescription.textLater': {
    defaultMessage: 'You decided to send your prescription later',
    id: 'dashboard.orderDetails.prescription.textLater'
  },
  'dashboard.orderDetails.prescription.textUpload': {
    defaultMessage: "You've uploaded your prescription",
    id: 'dashboard.orderDetails.prescription.textUpload'
  },
  'dashboard.orderDetails.prescription.title': {
    defaultMessage: 'Prescription',
    id: 'dashboard.orderDetails.prescription.title'
  },
  'dashboard.orderTable.noOrders': {
    defaultMessage: 'No orders yet',
    id: 'dashboard.orderTable.noOrders'
  },
  'dashboard.orderTable.orderStatusLabel': {
    defaultMessage: 'order status',
    id: 'dashboard.orderTable.orderStatusLabel'
  },
  deletePrescriptionWarningDescription: {
    defaultMessage: 'Are you sure you want to delete this prescription?',
    id: 'userProfile.profile.deletePrescriptionWarningDescription'
  },
  deletePrescriptionWarningTitle: {
    defaultMessage: 'Delete Prescription',
    id: 'userProfile.profile.deletePrescriptionWarningTitle'
  },
  delivery: {
    defaultMessage: 'Delivery',
    id: 'priceDetails.delivery'
  },
  deliveryMethod: {
    defaultMessage: 'Delivery method:',
    id: 'profile.orderDetails.deliveryMethod'
  },
  deliveryRange: {
    defaultMessage: 'estimated delivery <b>{min} – {max}</b>',
    id: 'dashboard.orderTable.deliveryRange'
  },
  deliveryTrackAndTraceDisclaimer: {
    defaultMessage: 'Tracking number will become available once order is shipped.',
    id: 'profile.orderDetails.deliveryTrackAndTraceDisclaimer'
  },
  discount: {
    defaultMessage: 'Discount',
    id: 'priceDetails.discount'
  },
  downloadButton: {
    defaultMessage: 'Download invoice',
    id: 'dashboard.orderDownload.downloadInvoice'
  },
  downloadReturnForm: {
    defaultMessage: 'Download return form (pdf)',
    id: 'returnFlow.downloadRetunForm'
  },
  downloadReturnFormLink: {
    defaultMessage: 'Download return form',
    id: 'dashboard.downloadReturnFormLink'
  },
  downloadReturnLabel: {
    defaultMessage: 'Download return label (pdf)',
    id: 'returnFlow.downloadRetunLabel'
  },
  downloadReturnLabelLink: {
    defaultMessage: 'Download return label',
    id: 'dashboard.downloadReturnLabelLink'
  },
  downloadReturnLabelText: {
    defaultMessage: 'Thank you! Download the return label and follow the instructions below.',
    id: 'returnFlow.downloadReturnLabelText'
  },
  downloadWillStart: {
    defaultMessage: 'Download will start automatically, if not click again.',
    id: 'dashboard.orderDownload.downloadWillStart'
  },
  editPrescriptionTitle: {
    defaultMessage: 'Edit Prescription',
    id: 'userProfile.profile.editPrescriptionTitle'
  },
  emailChangeNotPossible: {
    defaultMessage: 'To change your email please reach out to our customer support team.',
    id: 'userProfile.profile.emailChangeNotPossible'
  },
  free: {
    defaultMessage: 'Free',
    id: 'priceDetails.free'
  },
  giftcardCode: {
    defaultMessage: 'Using code {code}',
    id: 'priceDetails.giftcardCode'
  },
  glassesTab: {
    defaultMessage: 'Glasses',
    id: 'userProfile.prescriptions.glassesTab'
  },
  'in production': {
    defaultMessage: 'your order is in production',
    id: 'dashboard.orderTable.inProduction'
  },
  index_174: {
    defaultMessage: '1.74 lenses',
    id: 'profile.orderDetails.ultraThinLensesIndex'
  },
  account: {
    defaultMessage: 'Account',
    id: 'profile.account'
  },
  menu: {
    defaultMessage: 'Menu',
    id: 'profile.menu'
  },
  newPrescriptionLenstypeTitle: {
    defaultMessage: 'Choose your type of lenses',
    id: 'userProfile.newPrescription.lenstype.title'
  },
  newPrescriptionTitle: {
    defaultMessage: 'New prescription',
    id: 'userProfile.prescriptions.newPrescriptionTitle'
  },
  noOrdersYet: {
    defaultMessage: "You haven't placed any orders yet. Wanna start shopping?",
    id: 'userProfile.prescriptions.noOrdersYet'
  },
  noPrescriptionYet: {
    defaultMessage: "You don't have any saved prescriptions yet.",
    id: 'userProfile.prescriptions.noPrescriptionYet'
  },
  'order placed': {
    defaultMessage: 'your order has been placed',
    id: 'dashboard.orderTable.orderPlaced'
  },
  orderCancelledConfirmation: {
    defaultMessage:
      "We're sad that you decided to cancel your order. Your money will be sent back in 2 - 8 days. If you have any questions, please contact our {customerSupportLink}.",
    id: 'profile.orderDetails.orderCancelledConfirmation'
  },
  orderCancelledConfirmationTitle: {
    defaultMessage: 'Order canceled',
    id: 'profile.orderDetails.orderCancelledConfirmationTitle'
  },
  orderDownloadError: {
    defaultMessage: 'something is going wrong, please try again later',
    id: 'dashboard.orderDownload.error'
  },
  packReturnProduct: {
    defaultMessage: 'Add the product(s) you want to return in your package and seal it well.',
    id: 'returnFlow.packReturnProduct'
  },
  pdMissingDisclaimer: {
    defaultMessage:
      "We need this number so we can ensure you're looking through the correct part of the lens. If you don't know your PD, you can use our online measuring tool. When you have it, update your PD here and we can put your order into production.",
    id: 'userProfile.orderDetails.pdMissingDisclaimer'
  },
  polarised: {
    defaultMessage: 'Polarised',
    id: 'profile.orderDetails.polarised'
  },
  premium_lenses: {
    defaultMessage: 'Premium Lenses',
    id: 'profile.orderDetails.premiumLenses'
  },
  prepairingDownload: {
    defaultMessage: 'preparing your download',
    id: 'dashboard.orderDownload.prepairingDownload'
  },
  prescriptionExpiredBody: {
    defaultMessage:
      'This prescription expired. You need a valid prescription to be able to shop for corrective glasses.',
    id: 'userProfile.prescriptions.prescriptionExpiredBody'
  },
  prescriptionExpiredEyeTestChunk: {
    defaultMessage: 'book an eye test with us',
    id: 'userProfile.prescriptions.prescriptionExpiredEyeTestChunk'
  },
  prescriptionExpiredReadMoreBody: {
    defaultMessage:
      'You can opt to {eyeTestLink} or add a valid prescription during checkout, if you already have one. You can delete the expired prescription if you want.',
    id: 'userProfile.prescriptions.prescriptionExpiredReadMoreBody'
  },
  prescriptionRejectedCheckDetails: {
    defaultMessage: 'Check the order details for more information',
    id: 'userProfile.orderDetails.prescription.checkDetails'
  },
  prescriptionRejectedWithReason: {
    defaultMessage: `Your prescription was rejected because {reason}. We can't start producing your order without it.`,
    id: 'dashboard.orderStatus.prescriptionRejectedWithReason'
  },
  prescriptionRequirementsChangedBody: {
    defaultMessage:
      'According to local regulations we can only produce orders which use a verified prescription, meaning one that has been checked by one of our opticians. Now, to add a prescription to your account, you can either do an eye test at one of our stores or upload a prescription photo during checkout – and one of our opticians will check and verify it.',
    id: 'userProfile.prescriptions.requirementsChangedBody'
  },
  prescriptionRequirementsChangedTitle: {
    defaultMessage: 'The way to add a prescription has changed',
    id: 'userProfile.prescriptions.requirementsChangedTitle'
  },
  onItsWayPickup: {
    defaultMessage:
      "Our colleagues will perform a quality check to make sure your frames are perfect! We'll notify you via email when they're ready for pickup.",
    id: 'userProfile.orderStatus.shippedPickup'
  },
  printReturnLabel: {
    defaultMessage: 'Print the return label and fix it on the outside of your package.',
    id: 'returnFlow.printReturnLabel'
  },
  purchaseCompletedAt: {
    defaultMessage: 'Purchase date: {date}',
    id: 'profile.orderDetails.purchaseCompletedAt'
  },
  reOrder: {
    defaultMessage: 'Place re-order',
    id: 'dashboard.reOrderButton'
  },
  reOrder174ProductModalBody: {
    defaultMessage:
      'Sadly we no longer offer 1.74 index lenses. We now offer premium lenses, our thinnest lenses yet. You may add premium lenses to your order or proceed with standard lenses.',
    id: 'dashboard.reOrder174ProductModalBody'
  },
  reOrder174ProductPremiumDisclaimer: {
    defaultMessage:
      'Note: If you opt for standard lenses and have a high prescription, you may be required to add premium lenses during checkout.',
    id: 'dashboard.reOrder174ProductPremiumDisclaimer'
  },
  reOrderComputerLensesModalBody: {
    defaultMessage:
      'Our new range of Computer Glasses has officially launched. Sadly, they’re currently not available to be purchased online. Not to worry though - just swing by your local Ace & Tate store and you can pick up your new pair today!',
    id: 'dashboard.reOrderComputerLensesModalBody'
  },
  reOrderModalBody: {
    defaultMessage:
      'The {product} from this order cannot be re-ordered online. The other items from this order will be added to your cart.',
    id: 'dashboard.reOrderFlow.modal'
  },
  reOrderModalCta: {
    defaultMessage: 'Continue',
    id: 'dashboard.reOrderFlow.modalCta'
  },
  reOrderWithPremiumModalCta: {
    defaultMessage: 'Add premium lenses',
    id: 'dashboard.reOrderFlow.modalCtaPremium'
  },
  reOrderWithStandardLensesModalCta: {
    defaultMessage: 'Re-order with standard lenses',
    id: 'dashboard.reOrderFlow.modalCtaStandard'
  },
  requestReturn: {
    defaultMessage: 'Request return',
    id: 'dashboard.requestReturnButton'
  },
  requestReturnCta: {
    defaultMessage: 'Confirm request',
    id: 'dashboard.orderTable.request.returnCta'
  },
  requestReturnTitle: {
    defaultMessage: 'Request return',
    id: 'returnFlow.requestReturnModalTitle'
  },
  returnConfirmEmail: {
    defaultMessage:
      'As soon as we receive your package you will receive a confirmation email from us. You should receive your money back in XX days.',
    id: 'returnFlow.returnConfirmEmail'
  },
  returnConfirmTitle: {
    defaultMessage: 'Your personal details',
    id: 'returnFlow.returnConfirmTitle'
  },
  returnCustomerSupportLink: {
    defaultMessage: 'customer support',
    id: 'returnFlow.returnCustomerSupportLink'
  },
  returnFlowCountryLabel: {
    defaultMessage: 'Country',
    id: 'returnFlow.countryLabel'
  },
  returnFlowCountryMismatchError: {
    defaultMessage: 'You may only request a return from the same country as your original order',
    id: 'returnFlow.countryMismatchError'
  },
  returnGoodToKnow: {
    defaultMessage: `
    <b>Good to know:</b>
    <ol>
    <li>You can only return your order within 30 days after the purchase.</li>
    <li>You can only generate one return label per order. In case you ordered multiple items and return it partially, you need to contact {customerSupportLink} if you want to return more items later on.</li>
    </ol>`,
    id: 'returnFlow.returnGoodToKnow'
  },
  returnInstructions: {
    defaultMessage: 'What to do now?',
    id: 'returnFlow.returnInstructions'
  },
  returnInstructionsDrawerTitle: {
    defaultMessage: 'Return instructions',
    id: 'returnFlow.returnInstructionsDrawerTitle'
  },
  returnReasonTitle: {
    defaultMessage: "What's the reason for returning?",
    id: 'returnFlow.returnReasonTitle'
  },
  returnRequestFailed: {
    defaultMessage: 'Return request failed',
    id: 'returnFlow.returnRequestFailed'
  },
  returned: {
    defaultMessage: 'we have received your return order',
    id: 'dashboard.orderTable.returned'
  },
  saveChanges: {
    defaultMessage: 'Save Changes',
    id: 'userProfile.saveChanges'
  },
  savedPrescriptions: {
    defaultMessage: 'Saved prescriptions',
    id: 'profile.prescriptions.savedPrescriptions'
  },
  seeAllTab: {
    defaultMessage: 'See all',
    id: 'userProfile.prescriptions.seeAllTab'
  },
  selectReturnReason: {
    defaultMessage: 'Select reason',
    id: 'returnFlow.selectReturnReason'
  },
  sessionExpiredDescription: {
    defaultMessage:
      'Your session has expired. This can happen if you have been inactive for a while or recently changed the password to your account. Please log-in again to continue.',
    id: 'userProfile.profile.sessionExpiredDescription'
  },
  sessionExpiredTitle: {
    defaultMessage: 'Session Expired',
    id: 'userProfile.profile.sessionExpiredTitle'
  },
  shipped: {
    defaultMessage: 'your order has been delivered',
    id: 'dashboard.orderTable.delivered'
  },
  shipping: {
    defaultMessage: 'your order has been shipped',
    id: 'dashboard.orderTable.shipping'
  },
  shippingInfoTitle: {
    defaultMessage: 'Shipping Information',
    id: 'userProfile.profile.shippingInfoTitle'
  },
  submitPrescriptionButton: {
    defaultMessage: 'Submit prescription',
    id: 'userProfile.orderDetails.submitPrescriptionButton'
  },
  submitYourPdButton: {
    defaultMessage: 'Add PD',
    id: 'userProfile.orderDetails.submitYourPdButton'
  },
  subtotalInclVAT: {
    defaultMessage: 'Subtotal (incl. VAT)',
    id: 'priceDetails.subtotalInclVAT'
  },
  totalInclVAT: {
    defaultMessage: 'Total (incl. VAT)',
    id: 'priceDetails.totalInclVAT'
  },
  trackYourOrder: {
    defaultMessage: 'Track your order',
    id: 'profile.orderDetails.trackYourOrder'
  },
  unknown: {
    defaultMessage:
      'Order status currently unknown, check back later or contact customer service at {email} or {phone}',
    id: 'dashboard.orderTable.unknown'
  },
  uploadAgain: {
    defaultMessage: 'Upload again',
    id: 'dashboard.orderDetails.prescription.uploadAgain'
  },
  userInfoTitle: {
    defaultMessage: 'User Information',
    id: 'userProfile.profile.userInfoTitle'
  }
});

export const ctaMessages = defineMessages({
  viewMyOrders: {
    defaultMessage: 'View my orders',
    id: 'userProfile.cta.viewMyOrders'
  },
  viewMyPrescriptions: {
    defaultMessage: 'View my prescriptions',
    id: 'userProfile.cta.viewMyPrescriptions'
  },
  manageAccount: {
    defaultMessage: 'Manage account',
    id: 'userProfile.cta.manageAccount'
  },
  bookEyeTest: {
    defaultMessage: 'Book eye test',
    id: 'userProfile.cta.bookEyeTest'
  },
  addPrescription: {
    defaultMessage: 'Add prescription',
    id: 'userProfile.cta.addPrescription'
  }
});

const infoCardMessages = defineMessages({
  accountInformationCardTitle: {
    defaultMessage: 'This is you',
    id: 'infoCards.accountInformation.title'
  }
});

const overviewMessages = defineMessages({
  userOverviewIntro: {
    defaultMessage:
      'Let your eyes do the talking. Here you can easily manage your account, track orders and return items.',
    id: 'userProfile.overview.intro'
  }
});

const appointmentsMessages = defineMessages({
  acuityFetchError: {
    defaultMessage:
      'Oops, Something went wrong. Information about your appointment may be incomplete or incorrect. Some features may be disabled. Try again later.',
    id: 'appointments.acuityFetchError'
  },
  editAppointment: {
    defaultMessage: 'Edit appointment',
    id: 'appointments.action.editAppointment'
  },
  eyeTestAppointmentLabel: {
    defaultMessage: 'Eye test appointment',
    id: 'appointments.label.eyeTestAppointment'
  },
  goToPrescriptions: {
    defaultMessage: 'Go to my prescriptions',
    id: 'appointments.action.goToPrescriptions'
  },
  previousAppointments: {
    defaultMessage: 'Previous appointments',
    id: 'appointments.previousAppointments'
  },
  upcomingAppointments: {
    defaultMessage: 'Upcoming appointments',
    id: 'appointments.upcomingAppointments'
  }
});

const menuTitles = defineMessages({
  menuAccountOverview: {
    defaultMessage: 'Overview',
    id: 'userProfile.menu.overview'
  },
  menuAccountPreferences: {
    defaultMessage: 'Account and preferences',
    id: 'userProfile.menu.accountPreferences'
  },
  menuAppointments: {
    defaultMessage: 'My appointments',
    id: 'userProfile.menu.menuAppointments'
  },
  menuOrderDetail: {
    defaultMessage: 'Order details',
    id: 'userProfile.menu.menuOrderDetail'
  },
  menuOrders: {
    defaultMessage: 'Orders',
    id: 'userProfile.menu.orders'
  },
  menuPrescriptions: {
    defaultMessage: 'Prescriptions',
    id: 'userProfile.menu.prescriptions'
  },
  menuSubscriptions: {
    defaultMessage: 'Newsletter subscriptions',
    id: 'userProfile.menu.subscriptions'
  },

  menuFrequentlyAskedQuestions: {
    defaultMessage: 'Frequently asked questions',
    id: 'userProfile.frequentlyAskedQuestions'
  },
  menuTitle: {
    defaultMessage: 'Hi {name}',
    id: 'userProfile.menu.title'
  },
  menuSubtitle: {
    defaultMessage: 'Find all your essential information right here',
    id: 'userProfile.menu.subtitle'
  }
});

export default {
  ...menuTitles,
  ...appointmentsMessages,
  ...generalMessages,
  ...overviewMessages,
  ...ctaMessages,
  ...infoCardMessages
};
