import React, { createContext, ReactNode, useContext, useRef } from 'react';
import { ContactLensVariant, ProductVariant } from 'types/torii';
import * as Sentry from '@sentry/nextjs';
import { createStore, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import getFrameConfigurations, { FrameConfigurations } from 'utils/frameConfigurations';
import { WebStore } from 'types/webStore';
import { isSunny } from 'utils/product/productHelpers';
import { fetchCustomizationsByPrescriptionId, getCartLensProductionType, getPrescriptionType } from './helpers';
import * as STEPS from './partials/ConfiguratorSteps';
import { Locale } from 'types/locale';
import { MappedLensCustomizationProps, SelectedCustomizationProps, MappedExtraCustomizationProps } from './types';
import { fetchProductBySku, getUpsellProducts } from 'services/productsService';
import { useCartService } from 'services/cartService';
import { ServicesContext, Services } from 'services/context';
import { cartEvents } from 'tracking';
import useAddToCart, { UseAddToCartProps } from 'utils/hooks/useAddToCart';
import { FrameLine, LensColor, Line } from 'types/solidus';
import {
  getPrescriptionIdFromLine,
  transformLineToOlcCustomizations,
  transformLineToOlcLensType
} from 'utils/cartLine';
type WarningModalType =
  | 'multifocalPrescriptionWarning'
  | 'invalidPrescriptionCombination'
  | 'incompatibleConfiguration'
  | null;

type MappedCustomizations = {
  extras: MappedExtraCustomizationProps[];
  lenses: MappedLensCustomizationProps[];
};

export interface ConfiguratorState {
  webStore: WebStore;
  locale: Locale;
  data: {
    warningModal: WarningModalType;
    currentStep: STEPS.ConfiguratorStepsType;
    variant: ProductVariant;
    isOpen: boolean;
    isLoading: boolean;
    activeEditLine?: Line;
    loadingPromise: Promise<any>;
    prescriptionId: string;
    lensType: string;
    variantConfiguration: FrameConfigurations;
    availableCustomizations: Record<string, MappedCustomizations>;
    selectedLensExtras: MappedExtraCustomizationProps[];
    additionalProductsToAdd: ProductVariant[];
    upsellProducts: (ProductVariant | ContactLensVariant)[];
  };
  methods: {
    doPreviousStep: () => void;
    doNextStep: () => void;
    addToCart: (something: any) => void; // TODO: Types
    setLoading: (isLoading: boolean) => void;
    setVariant: (variant: ProductVariant, isEdit?: boolean) => void;
    setOpen: (isOpen: boolean) => void;
    setCurrentStep: (stepId: STEPS.ConfiguratorStepsType) => void;
    setWarningModal: (type: WarningModalType) => void;
    setPrescriptionId: (prescriptionId: string) => Promise<any>;
    setLensType: (lensType: string) => void;
    resetConfigurator: (options: { keepVariant: boolean }) => void;
    startEditFromLine: (line: Line) => void;
    updateUpsellProducts: (sku: string) => void;
    setLensExtra: (customizationId: string) => void;
    toggleAdditionalProduct: (product: ProductVariant) => void;
    updateAvailableCustomizations: () => void;
  };
  computed: {
    configuratorFlow: any;
    totalAmount: any;
    currentStepIndex: number;
  };
}

const DEFAULT_STATE = {
  isOpen: false,
  activeEditLine: null,
  variant: null,
  warningModal: null,
  currentStep: null,
  prescriptionId: null,
  lensType: null,
  isLoading: false,
  loadingPromise: null,
  variantConfiguration: null,
  availableCustomizations: {
    single_vision: null,
    multifocal: null
  },
  additionalProductsToAdd: [],
  upsellProducts: [],
  selectedLensExtras: []
};

function createConfiguratorStore(
  addToCart: (props: UseAddToCartProps) => void,
  cartService: any,
  servicesContext: Services
) {
  return createStore<ConfiguratorState>()(
    immer(
      devtools((set, get) => ({
        webStore: servicesContext.webStore,
        locale: servicesContext.locale,
        data: {
          ...DEFAULT_STATE
        },
        methods: {
          async addToCart() {
            const state = get();
            const {
              additionalProductsToAdd,
              selectedLensExtras,
              lensType,
              variantConfiguration,
              prescriptionId,
              variant,
              activeEditLine
            } = state.data;
            const { doNextStep, setLoading } = state.methods;
            setLoading(true);

            const productsToAdd =
              additionalProductsToAdd && additionalProductsToAdd.length > 0 ? additionalProductsToAdd : null;

            let lensColor: LensColor = null;

            if (isSunny(variant)) {
              lensColor = 'sunny';
            } else {
              const hasBlf =
                selectedLensExtras?.find(c => c.customizationKey === 'lens_color_blf') ||
                prescriptionId === 'bluelight-filter';

              const hasPhotochromic = selectedLensExtras?.find(c => c.customizationKey === 'lens_color_photochromic');

              if (hasBlf) {
                lensColor = 'UV420';
              } else if (hasPhotochromic) {
                lensColor = 'ATGREY01TR';
              }
            }

            const hasPolarisedLenses = selectedLensExtras?.some(c => c.customizationKey === 'polarised_lenses');
            const lensUpgradeType = variantConfiguration.lenses.find(l => l.id === lensType)?.surchargeId;
            const isLegacyPremiumLensType = lensUpgradeType === 'premium_lenses';

            if (activeEditLine) {
              const newLine = {
                ...activeEditLine,
                lens_color: lensColor,
                lens_upgrade_type: isLegacyPremiumLensType ? null : lensUpgradeType,
                polarised: hasPolarisedLenses,
                prescription_type: getPrescriptionType(prescriptionId),
                lenses_production_type: getCartLensProductionType(prescriptionId)
              } as FrameLine;

              await cartService.updateCartLine({
                new: newLine,
                original: activeEditLine
              });

              if (productsToAdd) {
                await cartService.addToCart(
                  productsToAdd.map(product => ({
                    variant_id: product.id
                  }))
                );
              }
            } else {
              await addToCart({
                additionalProducts: productsToAdd,
                lens_upgrade_type: isLegacyPremiumLensType ? null : lensUpgradeType,
                polarised: hasPolarisedLenses,
                lenses_production_type: getCartLensProductionType(prescriptionId),
                premiumLenses: isLegacyPremiumLensType,
                productType: 'frame',
                prescriptionType: getPrescriptionType(prescriptionId),
                lensColor: lensColor,
                redirectToCart: false,
                variant: state.data.variant
              });
            }

            if (productsToAdd) {
              productsToAdd.forEach((product: ProductVariant) => {
                cartEvents.addUpsellProducts({ from: 'pdp', sku: product.sku });
              });
            }

            setLoading(false);
            doNextStep();
          },
          doPreviousStep() {
            const state = get();
            const { configuratorFlow, currentStepIndex } = state.computed;

            set(
              state => {
                if (currentStepIndex < configuratorFlow.length) {
                  state.data.currentStep = configuratorFlow[Math.max(currentStepIndex - 1, 0)].id;
                }
              },
              false,
              'configurator/doPreviousStep'
            );
          },
          async doNextStep() {
            const state = get();
            // if a user progresses to the next step while we are still fetching data in the background
            // we show a loading spinner and wait for data fetching to complete first
            if (state.data.loadingPromise) {
              set(state => {
                state.data.isLoading = true;
              });
              state.data.loadingPromise.then(() => {
                setData();
              });
            } else {
              setData();
            }

            function setData() {
              // setData needs to get() the latest state when it runs,
              // so that all changes are considered when the promise is resolved
              const state = get();
              const { configuratorFlow, currentStepIndex } = state.computed;

              set(
                state => {
                  state.data.isLoading = false;
                  if (currentStepIndex < configuratorFlow.length) {
                    state.data.currentStep = configuratorFlow[Math.max(currentStepIndex + 1, 1)].id;
                  }
                },
                false,
                'configurator/doNextStep'
              );
            }
          },
          async startEditFromLine(line) {
            const { locale, methods } = get();
            const product = await fetchProductBySku(line.variant.sku, locale);

            if (!product) {
              const err = new Error(
                `Configurator Edit opened with invalid product SKU ${line.variant.sku}. ${JSON.stringify(product)}`
              );
              Sentry.captureException(err);
              return;
            }
            set(state => {
              state.data.activeEditLine = line;
            });

            const lensExtras = transformLineToOlcCustomizations(line);
            methods.setVariant(product.currentVariant as ProductVariant, true);
            await methods.setPrescriptionId(getPrescriptionIdFromLine(line));
            const { data } = get();

            set(
              state => {
                state.data.lensType = transformLineToOlcLensType(
                  line,
                  data.availableCustomizations[data.prescriptionId].lenses,
                  data.prescriptionId
                );
                state.data.isOpen = true;
                state.data.additionalProductsToAdd = [];
              },
              false,
              'configurator/startEditFromLine'
            );

            // lensExtras do not support multiple selections on the client anymore
            // in theory no more than one option should be returned, so we override
            // extras if there are more than one for any (legacy) reason
            lensExtras.forEach(extra => {
              methods.setLensExtra(extra);
            });

            const { computed } = get();
            set(state => {
              state.data.currentStep = computed.configuratorFlow[0].id;
            });
          },
          async updateAvailableCustomizations() {
            const { data, locale } = get();

            if (!data.availableCustomizations[data.prescriptionId]) {
              const availableCustomizationsPromise = fetchCustomizationsByPrescriptionId(
                data.prescriptionId,
                data.variant,
                data.variantConfiguration,
                locale
              );
              set(state => {
                state.data.loadingPromise = availableCustomizationsPromise;
              });

              return availableCustomizationsPromise.then(availableCustomizations => {
                set(
                  state => {
                    state.data.availableCustomizations[data.prescriptionId] = availableCustomizations;
                    state.data.loadingPromise = null;
                  },
                  false,
                  'configurator/updateAvailableCustomizations'
                );
              });
            }
          },
          async updateUpsellProducts(sku) {
            const { locale } = get();
            // TODO implement draft mode flag on third function argument true / false
            const products = await getUpsellProducts(sku, locale, false);

            set(
              state => {
                state.data.upsellProducts = products;
              },
              false,
              'configurator/updateUpsellProducts'
            );
          },
          setOpen: isOpen =>
            set(
              state => {
                state.data.isOpen = isOpen;
              },
              false,
              'configurator/setOpen'
            ),
          setLoading: isLoading =>
            set(
              state => {
                state.data.isLoading = isLoading;
              },
              false,
              'configurator/setLoading'
            ),
          resetConfigurator: options => {
            if (options.keepVariant) {
              set(
                state => {
                  state.data = {
                    ...DEFAULT_STATE,
                    variant: state.data.variant,
                    variantConfiguration: state.data.variantConfiguration,
                    upsellProducts: state.data.upsellProducts,
                    availableCustomizations: state.data.availableCustomizations
                  };
                },
                false,
                'configurator/resetConfigurator'
              );
            } else {
              set(
                state => {
                  state.data = DEFAULT_STATE;
                },
                false,
                'configurator/resetConfigurator'
              );
            }
          },
          setVariant: async (variant, isEdit) => {
            const { webStore } = get();
            const configuration = getFrameConfigurations(variant, webStore);

            set(
              state => {
                state.data.variant = variant;
                state.data.variantConfiguration = configuration;

                // reset all customizations when variants are changed. Some customizations might not be available
                // for specific variants
                if (!isEdit) {
                  if (state.data.availableCustomizations) {
                    for (const key in state.data.availableCustomizations) {
                      state.data.availableCustomizations[key] = null;
                    }
                  }
                }
              },
              false,
              'configurator/setVariant/intializeVariantData'
            );

            const { updateUpsellProducts } = get().methods;
            updateUpsellProducts(variant.sku);

            // reset configurator position after variant has been set and configuratorFlow was updated
            if (!isEdit) {
              const { configuratorFlow } = get().computed;

              set(
                state => {
                  state.data.currentStep = configuratorFlow[0]?.id;
                },
                false,
                'configurator/setVariant/resetCurrentStep'
              );
            }
          },
          setCurrentStep: stepId =>
            set(
              state => {
                state.data.currentStep = stepId;
              },
              false,
              'configurator/setCurrentStep'
            ),
          async setPrescriptionId(prescriptionId) {
            const { methods } = get();

            set(
              state => {
                state.data.prescriptionId = prescriptionId;
                state.data.lensType = null;
                state.data.selectedLensExtras = [];
              },
              false,
              'configurator/setPrescriptionId'
            );
            return methods.updateAvailableCustomizations();
          },
          setLensType: lensType =>
            set(
              state => {
                state.data.lensType = lensType;
              },
              false,
              'configurator/setLensType'
            ),
          setWarningModal: type =>
            set(
              state => {
                state.data.warningModal = type;
              },
              false,
              'configurator/setWarningModal'
            ),
          toggleAdditionalProduct(product) {
            const { additionalProductsToAdd } = get().data;
            const productIndex = additionalProductsToAdd.findIndex(x => x.id === product.id);

            set(
              state => {
                if (productIndex < 0) {
                  state.data.additionalProductsToAdd.push(product);
                } else {
                  state.data.additionalProductsToAdd.splice(productIndex, 1);
                }
              },
              false,
              'configurator/toggleAdditionalProduct'
            );
          },
          setLensExtra(customizationId) {
            const { selectedLensExtras, availableCustomizations, prescriptionId } = get().data;

            const matchingSelectedLensExtras = selectedLensExtras?.find(
              (customization: SelectedCustomizationProps) => customization.customizationKey === customizationId
            );

            const matchingAvailableLensExtras = availableCustomizations[prescriptionId]?.extras?.find(
              (customization: SelectedCustomizationProps) => customization.customizationKey === customizationId
            );
            set(
              state => {
                if (matchingSelectedLensExtras) {
                  state.data.selectedLensExtras = selectedLensExtras.filter(
                    x => x.customizationKey !== customizationId
                  );
                } else if (matchingAvailableLensExtras) {
                  state.data.selectedLensExtras = [...selectedLensExtras, matchingAvailableLensExtras];
                }
              },
              false,
              'configurator/setLensExtra'
            );
          }
        },
        computed: {
          get totalAmount() {
            const state = get();
            if (!state) return;

            const {
              availableCustomizations,
              variant,
              prescriptionId,
              variantConfiguration,
              lensType,
              selectedLensExtras,
              additionalProductsToAdd
            } = state.data;

            if (!variant) {
              return 0;
            }
            const originalPrice = variant.price.value;
            const salePrice = variant.salePrice?.value;
            const basePrice = salePrice || originalPrice;

            const basePriceWithPrescription =
              basePrice + variantConfiguration?.prescriptions.find(x => x.id === prescriptionId)?.surcharge;
            const lensPrice =
              availableCustomizations[prescriptionId]?.lenses?.find(x => x.id === lensType)?.priceDetails?.value || 0;
            const lensExtrasPrice = selectedLensExtras.reduce((acc, val) => {
              return (acc += val.priceDetails?.value || 0);
            }, 0);
            const additionalProductsCost = additionalProductsToAdd.reduce((acc, product) => {
              return (acc += product.salePrice?.value || product.price.value);
            }, 0);

            return basePriceWithPrescription + lensPrice + lensExtrasPrice + additionalProductsCost;
          },
          get configuratorFlow() {
            const state = get();
            if (!state) return [];

            const { availableCustomizations, variant, prescriptionId } = state.data;
            const lensesCustomizationNeeded = availableCustomizations[prescriptionId]?.lenses?.length > 0;
            const lensesExtrasCustomizationNeeded = availableCustomizations[prescriptionId]?.extras?.length > 0;
            const isSunnyFrame = isSunny(variant);

            const prescriptionStep = isSunnyFrame ? STEPS.sunPrescription : STEPS.prescription;
            const lensesStep = isSunnyFrame ? STEPS.sunLenses : STEPS.lenses;
            const lensesExtrasStep = isSunnyFrame ? STEPS.sunLensesExtras : STEPS.lensesExtras;

            return [
              prescriptionStep,
              ...(lensesCustomizationNeeded ? [lensesStep] : []),
              ...(lensesExtrasCustomizationNeeded ? [lensesExtrasStep] : []),
              STEPS.accessories,
              STEPS.cart
            ];
          },
          get currentStepIndex() {
            const state = get();
            if (!state) return 0;

            const { configuratorFlow } = state.computed;
            const { currentStep } = state.data;

            return Math.max(configuratorFlow?.map(x => x.id).indexOf(currentStep) || 0, 0);
          }
        }
      }))
    )
  );
}

const ConfiguratorContext = createContext<ReturnType<typeof createConfiguratorStore> | null>(null);

export const ConfiguratorProvider = ({ children }: { children: ReactNode }) => {
  const store = useRef<ReturnType<typeof createConfiguratorStore> | null>();
  const ctx = useContext(ServicesContext);
  const addToCart = useAddToCart();
  const cartService = useCartService();
  if (!store.current) {
    store.current = createConfiguratorStore(addToCart, cartService, ctx);
  }

  return <ConfiguratorContext.Provider value={store.current}>{children}</ConfiguratorContext.Provider>;
};

export const useConfiguratorStore = () => {
  const store = useContext(ConfiguratorContext);
  if (store === null) {
    throw new Error('No configurator provider in component ancestry');
  }

  return useStore(store);
};
