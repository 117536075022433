import React, { useEffect, StrictMode, useState } from 'react';
import 'styles/typography.css';
import { useRouter } from 'next/router';
import { CountryDisabled } from 'features/Notifications/CustomNotifications';
import { IntlProvider } from 'react-intl';
import GlobalStyles from 'styles/global';
import { DesignSystemGlobalStyle, Toast } from '@aceandtate/ds';
import Cookie from 'js-cookie';
import { ServicesContext } from 'services/context';
import NotificationsContext from 'features/Notifications/NotificationsContext';
import Notifications from 'features/Notifications';
import ErrorBoundary from 'components/ErrorBoundary';
import CountryModal from 'components/CountryModal';
import LoginDrawer from 'components/AuthDrawer';
import { WishlistProvider } from 'features/Wishlist';
import { PdToolProvider } from 'features/PdTool';
import KustomerChat from 'features/KustomerChat';
import NewsletterPrompt from 'blocks/NewsletterPrompt';
import { setup, trackPageView, requiresLanguagePrompt } from 'utils/app/helpers';
import { FullTemplate } from 'templates';
import DraftModeNotice from 'components/DraftModeNotice';
import { Locale } from 'types/locale';
import { parseAndStoreUTM } from 'tracking/utils';
import { sentryReplayEnabled } from 'iris.config';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

const lazyloadSentryReplay = () => {
  return (
    sentryReplayEnabled &&
    import('@sentry/nextjs').then(Sentry => {
      Sentry.addIntegration(
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true
          // Additional SDK configuration goes in here..
        })
      );
    })
  );
};

if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_ENABLE_POSTHOG === 'true') {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_UI_HOST || 'https://eu.i.posthog.com',
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://res.aceandtate.com',
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    loaded: posthog => {
      if (process.env.NEXT_PUBLIC_IS_PRODUCTION !== 'true') posthog.debug(); // debug mode in development and show env
    },
    session_recording: {
      maskTextSelector: '[data-cs-mask]'
    }
  });
}

export default function MyApp({ Component, pageProps, err }) {
  const { messages, locale, draftMode, webStore } = pageProps;
  const router = useRouter();

  const [showCountryModal, setShowCountryModal] = useState(false);

  useEffect(() => {
    lazyloadSentryReplay();
  }, []);

  useEffect(() => {
    if (!locale) return;

    const htmlLang = locale.country === 'SE' ? 'sv' : locale.lang;

    document.documentElement.lang = htmlLang;

    const langObserver = new MutationObserver(() => {
      if (document.documentElement.lang !== htmlLang) {
        document.documentElement.lang = htmlLang;
      }
    });
    langObserver.observe(document.documentElement, {
      attributeFilter: ['lang']
    });

    return () => {
      langObserver.disconnect();
    };
  }, [locale]);

  const pageIdentifier = pageProps.key || pageProps.pageName;

  useEffect(() => {
    if (router.isFallback) return;

    if (pageProps.redirectTarget) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}${pageProps.redirectTarget}${window.location.search}${window.location.hash}`
      );
      return;
    }

    parseAndStoreUTM();
    trackPageView({
      ...pageProps,
      relativePath: router.asPath
    });
  }, [pageIdentifier, router.isFallback]);

  useEffect(() => {
    // temporary bandaid to client side redirect non-matching contentful collections

    if (!router.isFallback && !pageProps.redirectTarget) {
      setup(pageProps);

      const shouldShowLanguagePrompt =
        window.location.hash.includes('country-select') ||
        requiresLanguagePrompt(locale?.prefix, webStore?.country.iso);
      setShowCountryModal(shouldShowLanguagePrompt);
    }
  }, [router.isFallback]);

  const handleLocaleError = err => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(err); // eslint-disable-line
    }
  };

  function applyLocale() {
    setShowCountryModal(false);
    Cookie.set('v1-lang-preference', locale.prefix, { expires: 365 });
    history.pushState('', document.title, window.location.pathname + window.location.search);
  }

  if (router.isFallback || !locale) {
    return (
      <div>
        <Component {...pageProps} err={err} />
      </div>
    );
  }
  const getLayout = Component.getLayout || ((page: React.ReactElement) => <FullTemplate>{page}</FullTemplate>);

  const getIntlLocale = (locale: Locale) => {
    if (locale.country === 'SE') {
      return 'sv'; // forcing SV fallback for currency rendering in Sweden
    } else if (locale.lang === 'en' && locale.country !== 'US') {
      return 'en-gb'; // default to European English convention for dates, ..
    } else {
      return locale.lang;
    }
  };

  return (
    <StrictMode>
      <ErrorBoundary>
        <ServicesContext.Provider value={pageProps}>
          <IntlProvider
            locale={getIntlLocale(locale)}
            messages={messages}
            onError={handleLocaleError}
            wrapRichTextChunksInFragment
          >
            <PostHogProvider>
              <DesignSystemGlobalStyle>
                <WishlistProvider>
                  <Toast.Provider>
                    <NotificationsContext.Provider>
                      {showCountryModal && <CountryModal onClose={applyLocale} />}

                      {webStore?.disabled && <CountryDisabled />}
                      <LoginDrawer />
                      <KustomerChat />
                      <NewsletterPrompt />

                      <PdToolProvider>{getLayout(<Component {...pageProps} err={err} />, pageProps)}</PdToolProvider>

                      {draftMode && <DraftModeNotice />}
                      <Notifications />
                    </NotificationsContext.Provider>
                  </Toast.Provider>
                </WishlistProvider>
              </DesignSystemGlobalStyle>
            </PostHogProvider>
          </IntlProvider>
          <GlobalStyles />
        </ServicesContext.Provider>
      </ErrorBoundary>
    </StrictMode>
  );
}
