export const navHeightDesktop = 56;
export const navHeightMobile = 45;
export const ribbonHeightDesktop = 32;
export const ribbonHeightMobile = 28;
export const accountNavWidthFull = 375;

export const sizes = {
  large: 1023,
  small: 768,
  tiny: 540,
  xlarge: 1399,
  xxlarge: null
} as const;

export const breakpoints = {
  large: {
    max: sizes.large,
    min: sizes.small + 1
  },
  small: {
    max: sizes.small,
    min: sizes.tiny + 1
  },
  tiny: {
    max: sizes.tiny,
    min: 0
  },
  xlarge: {
    max: sizes.xlarge,
    min: sizes.large + 1
  },
  xxlarge: {
    min: sizes.xlarge + 1
  }
} as const;

export const namedBreakpoints = {
  dashboard: breakpoints.small.max,
  genericLargeMin: breakpoints.xxlarge.min,
  genericMobileMax: breakpoints.small.max,
  genericMobileMin: breakpoints.large.min,
  genericMobileXsMax: breakpoints.tiny.max
} as const;

export const breakpointRules = {
  large: `(min-width: ${breakpoints.large.min}px) and (max-width: ${breakpoints.large.max}px)`,
  largeMax: `(max-width: ${breakpoints.large.max}px)`,
  largeMin: `(min-width: ${breakpoints.large.min}px)`,
  small: `(min-width: ${breakpoints.small.min}px) and (max-width: ${breakpoints.small.max}px)`,
  smallMax: `(max-width: ${breakpoints.small.max}px)`,
  smallMin: `(min-width: ${breakpoints.small.min}px)`,
  tiny: `(max-width: ${breakpoints.tiny.min}px)`,
  tinyMax: `(max-width: ${breakpoints.tiny.max}px)`,
  xlarge: `(min-width: ${breakpoints.xlarge.min}px) and (max-width: ${breakpoints.xlarge.max}px)`,
  xlargeMax: `(max-width: ${breakpoints.xlarge.max}px)`,
  xlargeMin: `(min-width: ${breakpoints.xlarge.min}px)`,
  xxlarge: `(min-width: ${breakpoints.xlarge.min}px)`,
  xxlargeMin: `(min-width: ${breakpoints.xxlarge.min}px)`
};

// base values, do not touch or import
export const gridBase = {
  desktop: {
    l: 40,
    m: 24,
    s: 12,
    xl: 80,
    xs: 8,
    xxl: 120,
    xxs: 4
  },
  mobile: {
    xl: 56,
    xxl: 56
  },
  tiny: {
    l: 20,
    m: 16,
    xl: 24,
    xxl: 24
  }
};

export const grid = {
  /** 40px */
  l: 'var(--grid-l)',
  /** 24px */
  m: 'var(--grid-m)',
  /** 12px */
  s: 'var(--grid-s)',
  /** 80px desktop / 56px mobile */
  xl: 'var(--grid-xl)',
  /** 8px */
  xs: 'var(--grid-xs)',
  /** 120px desktop / 56px mobile */
  xxl: 'var(--grid-xxl)',
  /** 4px */
  xxs: 'var(--grid-xxs)'
} as const;
