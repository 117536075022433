import React from 'react';
import {
  IconAccount,
  IconCalendar,
  IconChevronRight,
  IconEye,
  IconGlasses,
  IconInfo,
  IconLetter,
  IconLogout,
  IconShoppingBag,
  Typography,
  grid
} from '@aceandtate/ds';
import Link from 'next/link';
import styled from 'styled-components';
import { paths } from 'paths';
import ProtectedRoute from 'blocks/ProtectedRoute';
import { FormattedMessage } from 'react-intl';
import messages from 'views/ProfileView/messages';
import authenticationMessages from 'messages/authentication';
import { AuthenticationFormV2State } from 'components/AuthenticationFormV2';

const IconSize = 24;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: ${grid[32]};
    margin: 0;

    :first-child {
      margin-bottom: ${grid[32]};
    }

    :last-child {
      margin-top: ${grid[32]};
    }
  }

  li {
    a,
    button {
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    span {
      display: flex;
      align-items: center;
      gap: 8px;

      font-size: 16px;
      font-weight: 500;
    }
  }
`;

type ProfileMenuProps = {
  onClickLink?: () => void;
  onFormChange?: React.Dispatch<React.SetStateAction<AuthenticationFormV2State>>;
};

const ProfileMenu = ({ onClickLink, onFormChange }: ProfileMenuProps) => {
  return (
    <ProtectedRoute isDrawer={true}>
      {({ logout, userProfile }) => (
        <>
          <Typography variant='h3' data-cs-mask>
            <FormattedMessage {...messages.menuTitle} values={{ name: userProfile?.customer?.firstname }} />
          </Typography>
          <Typography style={{ marginBottom: grid[32] }}>
            <FormattedMessage {...messages.menuSubtitle} />
          </Typography>
          <StyledNav>
            <ul>
              <li>
                <Link href={paths.userProfile} shallow onClick={onClickLink}>
                  <span>
                    <IconGlasses fontSize={IconSize} />
                    <Typography as='span'>
                      <FormattedMessage {...messages.menuAccountOverview} />
                    </Typography>
                  </span>
                  <IconChevronRight fontSize={IconSize} />
                </Link>
              </li>
              <li>
                <Link href={paths.userOrders} shallow onClick={onClickLink}>
                  <span>
                    <IconShoppingBag fontSize={IconSize} />
                    <Typography as='span'>
                      <FormattedMessage {...messages.menuOrders} />
                    </Typography>
                  </span>
                  <IconChevronRight fontSize={IconSize} />
                </Link>
              </li>
              <li>
                <Link href={paths.userAccountPreferences} shallow onClick={onClickLink}>
                  <span>
                    <IconAccount fontSize={IconSize} />
                    <Typography as='span'>
                      <FormattedMessage {...messages.menuAccountPreferences} />
                    </Typography>
                  </span>
                  <IconChevronRight fontSize={IconSize} />
                </Link>
              </li>
              <li>
                <Link href={paths.userPrescriptions} shallow onClick={onClickLink}>
                  <span>
                    <IconEye fontSize={IconSize} />
                    <Typography as='span'>
                      <FormattedMessage {...messages.menuPrescriptions} />
                    </Typography>
                  </span>
                  <IconChevronRight fontSize={IconSize} />
                </Link>
              </li>
              <li>
                <Link href={paths.userAppointments} shallow onClick={onClickLink}>
                  <span>
                    <IconCalendar fontSize={IconSize} />
                    <Typography as='span'>
                      <FormattedMessage {...messages.menuAppointments} />
                    </Typography>
                  </span>
                  <IconChevronRight fontSize={IconSize} />
                </Link>
              </li>
              <li>
                <Link href={paths.userSubscriptions} shallow onClick={onClickLink}>
                  <span>
                    <IconLetter fontSize={IconSize} />
                    <Typography as='span'>
                      <FormattedMessage {...messages.menuSubscriptions} />
                    </Typography>
                  </span>
                  <IconChevronRight fontSize={IconSize} />
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link href={paths.faq} target='_blank'>
                  <span>
                    <IconInfo fontSize={IconSize} />
                    <Typography as='span'>
                      <FormattedMessage {...messages.menuFrequentlyAskedQuestions} />
                    </Typography>
                  </span>
                </Link>
              </li>
              <li>
                <button
                  onClick={() => {
                    onClickLink();
                    window.setTimeout(() => {
                      onFormChange && onFormChange('email');
                      logout();
                    }, 250);
                  }}
                >
                  <span>
                    <IconLogout fontSize={IconSize} />
                    <Typography as='span'>
                      <FormattedMessage {...authenticationMessages.logout} />
                    </Typography>
                  </span>
                </button>
              </li>
            </ul>
          </StyledNav>
        </>
      )}
    </ProtectedRoute>
  );
};

export default ProfileMenu;
