import React from 'react';
import { Typography } from '@aceandtate/ds';
import Link from 'next/link';

import * as Styles from './styles';
import type { Menu } from 'blocks/MenuNavigation/types';
import { usePath } from 'paths';

interface Props {
  menuBlocks: Menu[];
}

export default function SecondaryMobileMenu({ menuBlocks }: Props) {
  const pathTo = usePath();

  return (
    <Styles.SecondaryMobileMenuContainer>
      {menuBlocks.map(menu => (
        <Link key={menu.sys.id} href={menu.urlRoute?.path || pathTo(menu.path)} prefetch={false}>
          <Typography variant='h5' color={menu.color}>
            {menu.text}
          </Typography>
        </Link>
      ))}
    </Styles.SecondaryMobileMenuContainer>
  );
}
