import styled from 'styled-components';
import { grid, brandColors, breakpointRules, IconButton, Typography } from '@aceandtate/ds';
import { ProgressIndicatorShell } from 'components/Configurators/FrameConfigurator/styles';

/** Drawer Global */
export const InnerDrawerContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`;

/** Top Section */
export const TopDrawer = styled.div``;

export const BackButton = styled(IconButton)``;
export const ProductName = styled(Typography)``;
export const CloseButton = styled(IconButton)``;
export const CartHeaderTitle = styled.div`
  padding: ${grid[12]} 0 ${grid[12]} ${grid[24]};
  width: 100%;

  @media ${breakpointRules.laptop} {
    padding-left: ${grid[40]};
  }
`;

const TopDrawerGrid = styled.div`
  --progressIndicatorHeight: 5px;
  --navButtonsWidth: minmax(44px, auto);

  display: grid;
  grid-template-rows: var(--progressIndicatorHeight) auto;
  grid-template-columns: var(--navButtonsWidth) 1fr var(--navButtonsWidth);

  align-items: start;
  position: relative;
  width: 100%;
`;

export const TopDrawerContainer = styled(TopDrawerGrid)`
  ${ProgressIndicatorShell} {
    grid-column: 1 / 4;
    grid-row: 1;
  }

  ${BackButton} {
    grid-column: 1;
    grid-row: 2;
  }

  ${ProductName} {
    grid-column: 2;
    grid-row: 2;

    align-self: center;
    text-align: center;
  }

  ${CloseButton} {
    grid-column: 3;
    grid-row: 2;
  }
`;

export const TopDrawerContainerCart = styled(TopDrawerGrid)`
  border-bottom: 1px solid ${brandColors.dark10};

  ${CartHeaderTitle} {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  ${CloseButton} {
    grid-column: 3;
    grid-row: 2;
  }
`;

/** Bottom Section */
export const BottomDrawer = styled.div`
  background: ${brandColors.light};
  border-top: 1px solid ${brandColors.dark10};
  box-sizing: border-box;
  margin-top: auto;
  padding: ${grid[12]} ${grid[24]};
  width: 100%;
`;

/** Content Section */

export const StepTitle = styled.div`
  display: flex;
  flex-direction: column;

  span {
    align-self: center;
  }
`;

export const SliderContainer = styled.aside`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  max-width: 100vw;
  height: 100%;
  overflow: auto;
`;

export const SlideHeader = styled.div`
  padding-bottom: ${grid[12]};
  padding-top: ${grid[40]};
  margin-top: ${grid[12]};
  position: relative;
  text-align: center;
`;

export const Slide = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-flow: column;
  overflow: auto;
  position: relative;
`;

type SlideAnimationInnerScrollProps = {
  stepIndex: number;
  disableAnimation?: boolean;
};

type SliderProps = {
  active?: boolean;
  fullScreen?: boolean;
  isTablet?: boolean;
  isCartStep?: boolean;
};

export const SlideContent = styled.div<SliderProps>`
  margin: ${grid[16]} ${grid[24]};

  @media ${breakpointRules.tablet} {
    margin: ${({ isCartStep }) => (isCartStep ? `${grid[16]} ${grid[40]}` : `${grid[16]} ${grid[60]}`)};
  }

  > ul {
    list-style: none;
    padding: 0;
    margin-top: ${grid[24]};
    margin-bottom: 0;

    li {
      margin-bottom: ${grid[12]};
    }
  }
`;

export const SlideAnimationWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

export const SlideAnimationInnerScroll = styled.div<SlideAnimationInnerScrollProps>`
  display: flex;
  height: 100%;
  transition: ${({ disableAnimation }) =>
    disableAnimation ? 'none' : `transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms`};
  transform: ${props => `translateX(-${props.stepIndex * 100}%)`};
`;
