import React, { useContext, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import PriceLabel from 'components/DisplayLabels/PriceLabel';
import SaleLabel from 'components/SaleLabel';
import { breakpointRules, Button, Typography, useMediaQuery, brandColors, Flex, grid, Drawer } from '@aceandtate/ds';
import { useCartState } from 'services/cartService';
import { ServicesContext } from 'services/context';
import { paths } from 'paths';
import { transform } from 'utils/images';
import { NON_PLANO_PRESCRIPTIONS } from 'globalConstants';
import type { ProductVariant } from 'types/torii';
import ecommerceMessages from 'messages/ecommerce';
import cartMessages from 'messages/cart';
import * as STEPS from './partials/ConfiguratorSteps';
import Configurator from './partials/Configurator';
import ConfiguratorOption from './partials/ConfiguratorOption';
import ConfiguratorCart from './partials/ConfiguratorCart';
import ConfiguratorModal from './partials/Modals';
import PolarisedHint from './partials/PolarisedHint';
import { trackArtificialPageView } from 'tracking/helpers';

import * as Styles from './styles';
import messages from './messages';
import { useConfiguratorStore } from './configuratorStore';
import { isValidPrescriptionCombinationWithCart } from './helpers';

const recommendedProductImageConfig = (isTablet?: boolean) => ({
  height: isTablet ? 300 : 500,
  quality: 100,
  width: isTablet ? 300 : 500
});

export default function FrameConfigurator({ variant: variantProp }: { variant?: ProductVariant }) {
  const { webStore } = useContext(ServicesContext);
  const { data, methods, computed } = useConfiguratorStore();

  const {
    variant,
    isOpen,
    currentStep,
    lensType,
    prescriptionId,
    selectedLensExtras,
    availableCustomizations,
    upsellProducts,
    additionalProductsToAdd,
    variantConfiguration,
    isLoading,
    warningModal,
    activeEditLine
  } = data;
  const { configuratorFlow, totalAmount } = computed;
  const {
    setVariant,
    doNextStep,
    doPreviousStep,
    setOpen,
    setLoading,
    setPrescriptionId,
    setLensType,
    setLensExtra,
    toggleAdditionalProduct,
    resetConfigurator,
    addToCart,
    setWarningModal
  } = methods;

  const isTablet = useMediaQuery(breakpointRules.tablet);
  const cartQuery = useCartState();
  const { cart } = cartQuery.data;
  const router = useRouter();

  const originalPrice = variant?.price.value;
  const salePrice = variant?.salePrice?.value;
  const isCartStep = currentStep === STEPS.cart.id;
  const isLensesStep = [STEPS.lenses.id, STEPS.sunLenses.id].includes(currentStep);
  const canEmitOrder = !warningModal && currentStep === STEPS.accessories.id;
  const askForPrescriptionLater = NON_PLANO_PRESCRIPTIONS.includes(prescriptionId);

  useEffect(() => {
    const onHashChangeComplete = () => {
      const { hash } = window.location;
      if (hash === '#multifocal-warning') {
        setWarningModal('multifocalPrescriptionWarning');
      }
    };
    if (isOpen) {
      // remove existing queries that might have persisted through reload of the page, to make sure we
      // do not cause any sideeffects
      const { hash } = window.location;
      if (hash === '#multifocal-warning') {
        router.replace({ hash: undefined, query: router.query }, undefined, { scroll: false, shallow: true });
      }
      addEventListener('popstate', onHashChangeComplete);
    } else {
      removeEventListener('popstate', onHashChangeComplete);
    }

    return () => {
      removeEventListener('popstate', onHashChangeComplete);
    };
  }, [isOpen]);
  // intializing variant and setting variant again if it changes on close (e.g. if a different variant was in edit mode)
  useEffect(() => {
    variantProp && setVariant(variantProp);
  }, [variantProp]);

  function handleClose() {
    setOpen(false);

    // waiting for drawer animation to complete before resetting
    window.setTimeout(() => {
      // returning variant to original variant if configurator was in edit mode
      if (variantProp?.sku !== variant?.sku && variantProp) {
        setVariant(variantProp);
      }
      resetConfigurator({ keepVariant: true });
      trackArtificialPageView('');
    }, 350);
  }

  // Send to checkout
  function attemptCheckout() {
    setLoading(true);
    router.push(paths.checkoutRegular);
  }

  // Artificial Pageview tracking
  useEffect(() => {
    if (!isOpen) return;
    const stepData = configuratorFlow.find(step => step.id === currentStep);
    stepData && trackArtificialPageView(activeEditLine ? stepData.editTrackName : stepData.trackName);
  }, [currentStep, isOpen]);

  const prescriptionCategories = useMemo(() => {
    if (!variantConfiguration) return [];

    const categories = Array.from(
      new Set(variantConfiguration.prescriptions.map(prescription => prescription.lensCategory?.props.defaultMessage))
    );

    return categories.map(categoryId => ({
      categoryId,
      categoryTitle: variantConfiguration.prescriptions.find(
        prescription => prescription.lensCategory?.props.defaultMessage === categoryId
      )?.lensCategory?.props,
      prescriptions: variantConfiguration.prescriptions.filter(
        prescription => prescription.lensCategory?.props.defaultMessage === categoryId
      )
    }));
  }, [variantConfiguration]);

  function handlePrescriptionSelect(newPrescriptionId: string) {
    if (cart && !isValidPrescriptionCombinationWithCart(newPrescriptionId, cart)) {
      setWarningModal('invalidPrescriptionCombination');
      return;
    }
    if (newPrescriptionId !== prescriptionId) {
      setPrescriptionId(newPrescriptionId);
    }
  }

  const configurationSummary = (
    <Styles.SummaryContainer>
      <Styles.SubTotalRow>
        <Typography variant='bodyM' color={brandColors.dark75}>
          <FormattedMessage {...ecommerceMessages.subtotal} />
        </Typography>
        <Typography variant='h3'>
          <PriceLabel
            data-testid='frame-info-total-amount'
            price={isCartStep ? cart.total : totalAmount}
            currency={variant?.price.currency}
          />
        </Typography>
      </Styles.SubTotalRow>
      {!canEmitOrder && !isCartStep && (
        <div>
          <Button
            color='accent'
            size='medium'
            onClick={doNextStep}
            disabled={!!warningModal || (isLensesStep && !lensType)}
            loading={isLoading}
            data-testid='configurator-next'
          >
            <FormattedMessage {...ecommerceMessages.next} />
          </Button>
        </div>
      )}
      {canEmitOrder && (
        <div>
          <Button
            color='accent'
            size='medium'
            onClick={addToCart}
            data-testid='configurator-add-to-cart'
            loading={isLoading}
          >
            {activeEditLine ? (
              <FormattedMessage {...ecommerceMessages.updateItem} />
            ) : (
              <FormattedMessage {...ecommerceMessages.addToCart} />
            )}
          </Button>
        </div>
      )}
      {!canEmitOrder && currentStep === STEPS.cart.id && (
        <div>
          <Button
            color='accent'
            size='medium'
            disabled={cart.regular_lines.length === 0}
            onClick={attemptCheckout}
            data-testid='configurator-place-order'
            loading={isLoading}
          >
            <FormattedMessage {...cartMessages.placeOrder} />
          </Button>
        </div>
      )}
    </Styles.SummaryContainer>
  );

  const prescriptionNoticeElm = (
    <Styles.NoticeBlock>
      <Typography variant='bodyM'>
        <FormattedMessage {...messages.askForPrescriptionLater} />
      </Typography>
    </Styles.NoticeBlock>
  );

  const multifocalExceptionElm = (
    <Styles.ExceptionBlock>
      <Typography variant='bodyM'>
        <FormattedMessage
          {...messages.multifocalNotice}
          values={{
            link: chunks => <Styles.UnderlineNextLink href={paths.stores}>{chunks}</Styles.UnderlineNextLink>
          }}
        />
      </Typography>
    </Styles.ExceptionBlock>
  );

  return (
    <>
      <ConfiguratorModal />
      <Drawer open={isOpen} onClose={handleClose} width='600px' position='right'>
        <Configurator
          titles={configuratorFlow.map(step => step.title)}
          open={isOpen}
          showSummary={!!prescriptionId}
          onBack={doPreviousStep}
          onClose={handleClose}
          summary={configurationSummary}
          totalSteps={configuratorFlow.length}
          currentVariant={variant}
          cart={cart}
        >
          {configuratorFlow.map(step => {
            if (step === STEPS.prescription) {
              return (
                <ul key={step.id}>
                  {prescriptionCategories.map(({ categoryId, prescriptions, categoryTitle }) => (
                    <Styles.CategoryBlock key={categoryId}>
                      <Typography variant='h5' color={brandColors.accent75} gutterBottom>
                        {categoryTitle ? <FormattedMessage {...categoryTitle} /> : categoryId}
                      </Typography>
                      {prescriptions.map(prescription => (
                        <li key={prescription.id}>
                          <ConfiguratorOption
                            data-testid={`${prescription.id}-item`}
                            title={prescription.title}
                            description={prescription.description}
                            infoLabel={
                              <Flex gap={grid[4]}>
                                <PriceLabel
                                  isBeforeSalePrice={!!salePrice}
                                  price={originalPrice + prescription.surcharge}
                                  currency={variant?.price.currency}
                                />

                                {!!salePrice && (
                                  <PriceLabel
                                    isSale
                                    price={salePrice + prescription.surcharge}
                                    currency={variant?.price.currency}
                                  />
                                )}
                              </Flex>
                            }
                            selected={prescriptionId === prescription.id}
                            onClick={() => handlePrescriptionSelect(prescription.id)}
                          />
                        </li>
                      ))}
                    </Styles.CategoryBlock>
                  ))}
                  {webStore.config.hasMultifocalDisabled && multifocalExceptionElm}
                </ul>
              );
            } else if (step === STEPS.sunPrescription) {
              // STEP_0: PRESCRIPTION TYPE
              return (
                <ul key={step.id}>
                  {variantConfiguration?.prescriptions.map(prescription => (
                    <li key={prescription.id}>
                      <ConfiguratorOption
                        data-testid={`${prescription.id}-item`}
                        title={prescription.title}
                        description={prescription.description}
                        key={prescription.id}
                        infoLabel={
                          <Flex gap={grid[4]}>
                            <PriceLabel
                              isBeforeSalePrice={!!salePrice}
                              price={originalPrice + prescription.surcharge}
                              currency={variant?.price.currency}
                            />

                            {!!salePrice && (
                              <PriceLabel
                                isSale
                                price={salePrice + prescription.surcharge}
                                currency={variant?.price.currency}
                              />
                            )}
                          </Flex>
                        }
                        selected={prescriptionId === prescription.id}
                        onClick={() => handlePrescriptionSelect(prescription.id)}
                      />
                    </li>
                  ))}
                  {webStore.config.hasMultifocalDisabled && multifocalExceptionElm}
                </ul>
              );
            } else if (step === STEPS.lenses || step === STEPS.sunLenses) {
              // STEP_1: LENSES TYPES
              return (
                <React.Fragment key={step.id}>
                  {askForPrescriptionLater && prescriptionNoticeElm}
                  <ul>
                    {availableCustomizations[prescriptionId]?.lenses?.map(lens => (
                      <li key={lens.id}>
                        <ConfiguratorOption
                          data-testid={`${lens.id}-feature`}
                          title={lens.title}
                          onClick={() => setLensType(lens.id)}
                          selected={lensType === lens.id}
                          description={lens.description}
                          key={lens.customizationKey}
                          infoLabel={
                            <PriceLabel
                              prefix='+'
                              price={lens.priceDetails.value}
                              currency={lens.priceDetails.currency}
                              whenZero={<FormattedMessage {...messages.free} />}
                            />
                          }
                        />
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              );
            } else if (step === STEPS.lensesExtras || step === STEPS.sunLensesExtras) {
              // STEP_2: LENSES EXTRAS
              return (
                <React.Fragment key={step.id}>
                  <ul>
                    {availableCustomizations[prescriptionId]?.extras?.map(extra => (
                      <li key={extra.id}>
                        <ConfiguratorOption
                          data-testid={`${extra.id}-feature`}
                          title={extra.title}
                          onClick={() => setLensExtra(extra.id)}
                          selected={!!selectedLensExtras?.some(c => extra.id === c.customizationKey)}
                          description={extra.description}
                          key={extra.customizationKey}
                          infoLabel={
                            <PriceLabel
                              prefix='+'
                              price={extra.priceDetails.value}
                              currency={extra.priceDetails.currency}
                              whenZero={<FormattedMessage {...messages.free} />}
                            />
                          }
                        />
                      </li>
                    ))}
                  </ul>
                  {availableCustomizations[prescriptionId]?.extras.some(c => c.id === 'polarised_lenses') && (
                    <PolarisedHint />
                  )}
                </React.Fragment>
              );
            } else if (step === STEPS.accessories) {
              // STEP_3: ACCESSORIES:
              return (
                <ul key={step.id}>
                  {upsellProducts?.map((item: ProductVariant) => {
                    const productIsSelected = additionalProductsToAdd?.some(
                      (product: ProductVariant) => product.id === item.id
                    );
                    return (
                      <li key={item.id}>
                        <ConfiguratorOption
                          title={
                            <>
                              {!!item.salePrice && <SaleLabel variant={item} />}
                              {item.name}
                            </>
                          }
                          hasSelector
                          selected={productIsSelected}
                          description={item.displayAttributes?.color}
                          imageSrc={transform(item.images.front?.url, recommendedProductImageConfig(isTablet))}
                          key={item.id}
                          data-testvalue={item.salePrice?.value || item.price.value}
                          data-testid={`${item.sku}-recommended-item`}
                          onClick={() => toggleAdditionalProduct(item)}
                          infoLabel={
                            <Flex gap={grid[4]}>
                              <PriceLabel
                                isBeforeSalePrice={!!item.salePrice}
                                price={item.price.value}
                                currency={webStore.config.currency}
                              />
                              {!!item.salePrice && (
                                <PriceLabel isSale price={item.salePrice.value} currency={webStore.config.currency} />
                              )}
                            </Flex>
                          }
                        />
                      </li>
                    );
                  })}
                </ul>
              );
            } else if (step === STEPS.cart) {
              // STEP_4: MY CART
              return <ConfiguratorCart key={step.id} />;
            }
            return null;
          })}
        </Configurator>
      </Drawer>
    </>
  );
}
