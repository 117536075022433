import React from 'react';
import { brandColors, IconClose, IconBack } from '@aceandtate/ds';
import { ProductVariant } from 'types/torii';
import { Cart } from 'types/solidus';
import ProgressIndicator from '../ProgressIndicator';
import * as Styles from './styles';
import { useConfiguratorStore } from '../../configuratorStore';
import usePrevious from 'utils/hooks/usePrevious';
import BundleDealCalloutBlock from 'components/BundleDealCalloutBlock';

type AdditionalContent = {
  pageIndex: number;
  component: React.ReactNode;
};

type ConfiguratorProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  titles: React.ReactNode[];
  promotion?: React.ReactNode;
  anchor?: 'bottom' | 'left' | 'right' | 'top';
  onBack?: (previousStepName?: string) => void;
  showSummary?: boolean;
  stepName?: string;
  summary?: React.ReactNode;
  additionalContent?: AdditionalContent[];
  totalSteps?: number;
  cart?: Cart;
  currentVariant: ProductVariant;
};

export default function Configurator(props: ConfiguratorProps) {
  const {
    onClose,
    onBack,
    children,
    titles,
    showSummary = false,
    summary = null,
    stepName = '',
    additionalContent,
    totalSteps,
    currentVariant
  } = props;
  const { data, computed } = useConfiguratorStore();
  const { currentStep, activeEditLine } = data;
  const { currentStepIndex } = computed;
  const drawerElements = React.Children.toArray(children);
  const isCartStep = currentStep === 'CART_STEP';
  const previousStep = usePrevious(currentStep);

  function findAdditionalContent(index: number) {
    const matchedContent = additionalContent && additionalContent.find(x => x.pageIndex === index);

    if (matchedContent) {
      return matchedContent.component;
    }

    return null;
  }

  return (
    <Styles.InnerDrawerContainer>
      <Styles.TopDrawer>
        {isCartStep ? (
          <Styles.TopDrawerContainerCart>
            <Styles.CartHeaderTitle>{titles[currentStepIndex]}</Styles.CartHeaderTitle>
            <Styles.CloseButton color='secondary' onClick={onClose} data-testid='close-button'>
              <IconClose fontSize={22.5} />
            </Styles.CloseButton>
          </Styles.TopDrawerContainerCart>
        ) : (
          <>
            <Styles.TopDrawerContainer>
              <ProgressIndicator totalSteps={totalSteps} stepIndex={currentStepIndex} />
              {onBack && currentStepIndex > 0 && (
                <Styles.BackButton
                  color='secondary'
                  onClick={() => onBack(stepName)}
                  disabled={currentStepIndex === 0}
                  data-testid='back-button'
                >
                  <IconBack fontSize={24} />
                </Styles.BackButton>
              )}
              <Styles.ProductName forwardedAs='span' variant='h6' color={brandColors.dark75}>
                {currentVariant && currentVariant.name} {currentVariant && currentVariant.displayAttributes.color}
              </Styles.ProductName>
              <Styles.CloseButton color='secondary' onClick={onClose} data-testid='close-button'>
                <IconClose fontSize={24} />
              </Styles.CloseButton>
            </Styles.TopDrawerContainer>
          </>
        )}
      </Styles.TopDrawer>
      <Styles.SliderContainer>
        <Styles.SlideAnimationWrapper>
          <Styles.SlideAnimationInnerScroll
            stepIndex={currentStepIndex}
            disableAnimation={activeEditLine && previousStep === 'CART_STEP' && !isCartStep}
          >
            {drawerElements.map((child, index) => (
              <Styles.Slide key={index}>
                <Styles.SlideContent isCartStep={isCartStep}>
                  {!isCartStep && <Styles.StepTitle>{titles[index]}</Styles.StepTitle>}
                  {child}
                </Styles.SlideContent>
                {findAdditionalContent(index)}
              </Styles.Slide>
            ))}
          </Styles.SlideAnimationInnerScroll>
        </Styles.SlideAnimationWrapper>
      </Styles.SliderContainer>
      {isCartStep && currentVariant.availability.isBundleDealEligible && <BundleDealCalloutBlock />}
      {showSummary && <Styles.BottomDrawer>{summary}</Styles.BottomDrawer>}
    </Styles.InnerDrawerContainer>
  );
}
