import React from 'react';
import Image from 'next/image';
import { Typography, breakpointRules, useMediaQuery, IconCheckCircle } from '@aceandtate/ds';
import * as Styles from './styles';

type ConfiguratorOptionProps = {
  description?: React.ReactNode;
  title: React.ReactNode;
  counter?: number;
  'data-testid'?: string;
  'data-testvalue'?: number;
  disabled?: boolean;
  focusContent?: React.ReactNode;
  imageCrop?: string;
  imageSrc?: string;
  infoLabel?: React.ReactNode;
  onClick?: () => void;
  hasSelector?: boolean;
  selected?: boolean;
  className?: string;
  alt?: string;
};

export default function ConfiguratorOption(props: ConfiguratorOptionProps) {
  const {
    title,
    description,
    infoLabel,
    selected,
    disabled,
    onClick,
    hasSelector,
    focusContent,
    imageSrc,
    imageCrop,
    alt = 'Ace & Tate | Product image',
    'data-testid': dataTestId = null,
    'data-testvalue': dataTestValue = null,
    className
  } = props;

  const isTablet = useMediaQuery(breakpointRules.tablet);

  const objectPositionMobile = imageCrop || '0 50%';
  const objectPositionDesktop = imageCrop ? 'bottom' : 'inherit';

  return (
    <Styles.Wrapper
      withImage={!!imageSrc}
      selected={selected}
      onClick={onClick}
      data-testid={dataTestId}
      data-testvalue={dataTestValue}
      className={className}
      disabled={disabled}
    >
      {imageSrc && (
        <Styles.ImageWrapper isMobile={!isTablet}>
          <div
            style={{
              position: 'relative',
              width: '100%',
              paddingBottom: !isTablet ? '60%' : '100%'
            }}
          >
            <Image
              src={imageSrc}
              unoptimized
              objectFit={!isTablet && imageCrop ? 'none' : 'cover'}
              objectPosition={!isTablet ? objectPositionMobile : objectPositionDesktop}
              fill
              alt={alt}
            />
          </div>

          {hasSelector && (
            <Styles.CheckWrap selected={selected}>
              <IconCheckCircle fontSize={32} />
            </Styles.CheckWrap>
          )}
        </Styles.ImageWrapper>
      )}
      <Styles.Content hasDescription={!!description} withImage={!!imageSrc}>
        <Styles.HeadingRow hasDescription={!!description} withImage={!!imageSrc}>
          <Styles.Title variant='h5' gutterBottom={!imageSrc && description && 8}>
            {title}
          </Styles.Title>
          {infoLabel && (
            <Styles.InfoLabel variant='h5' withImage={!!imageSrc}>
              {infoLabel}
            </Styles.InfoLabel>
          )}
        </Styles.HeadingRow>
        <Styles.ContentRow>
          {description && <Typography variant='bodyM'>{description}</Typography>}
          {selected && focusContent && <Styles.FocusContentContainer>{focusContent}</Styles.FocusContentContainer>}
          {!imageSrc && hasSelector && (
            <Styles.CheckWrap selected={selected}>
              <IconCheckCircle fontSize={32} />
            </Styles.CheckWrap>
          )}
        </Styles.ContentRow>
      </Styles.Content>
    </Styles.Wrapper>
  );
}
